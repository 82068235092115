(() => {
  "use strict";

  const { createApp, nextTick, computed } = Vue;

  // utilities
  // ==========================================================================

  // UUID生成
  const createUuid = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (a) => {
      let r = (new Date().getTime() + Math.random() * 16) % 16 | 0;
      let v = a == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

  // Cookie処理
  const Cookie = class {
    defaults = {
      maxAge: 60 * 60 * 24 * 30, // 保存期間 1か月
      resetExpires: "Thu, 01 Jan 1970 00:00:00 GMT", // リセット用日時
    };

    constructor() {}

    get() {
      const items = {};
      document.cookie.split(";").forEach((item) => {
        const detail = item.trim().split("=");
        items[detail[0]] = detail[1];
      });
      return items;
    }

    set(items) {
      for (const [key, value] of Object.entries(items)) {
        document.cookie = `${key}=${value}; max-age=${this.defaults.maxAge}`;
      }
    }

    reset(items) {
      items.forEach((item) => {
        document.cookie = `${item}=; expires=${this.defaults.resetExpires}`;
      });
    }
  };

  // ==========================================================================
  // chat
  // ==========================================================================

  window.Chat = class {
    constructor(elem, { url = "", useCookie = true } = {}) {
      this.elem = elem;

      this._instances = {
        cookie: new Cookie(),
      };

      // cookie
      // ==========================================================================

      this.options = {
        url,
        useCookie,
        userData: {},
      };

      this.useCookie(useCookie);

      const _this = this;

      // app
      // ==========================================================================

      this.app = createApp({
        data() {
          return {
            defaults: {
              // JSON読み込み先
              jsonUrl: _this.options.url,
              // 読み込み遅延ms
              loadingDelayMs: 1000,
              // 初期表示ページ
              loadingPageId: "welcome",
            },
            options: {
              // 表示ページ
              pageId: null,
            },
            results: {
              // JSON生データ
              jsonRaw: [],
            },
          };
        },
        provide() {
          return {
            app: {
              show: this.show,
              close: this.close,
              json: computed(() => this.jsonConverted),
            },
          };
        },
        computed: {
          jsonConverted() {
            // JSON配列をIDを元に連想配列化
            const jsonObject = {};
            this.results.jsonRaw.forEach((message) => {
              jsonObject[message.id] = message;
            });

            // 親メッセージID情報を保存
            for (const [messageId, message] of Object.entries(jsonObject)) {
              message.next_id.split(",").forEach((nextId) => {
                if (jsonObject[nextId]) {
                  jsonObject[nextId].parent = messageId;
                }
              });
            }

            return jsonObject;
          },
          pageComponentName() {
            return `chat-${this.options.pageId}`;
          },
        },
        methods: {
          show(pageId) {
            this.options.pageId = pageId;
          },
          close() {
            this.options.pageId = null;
          },
        },
        mounted() {
          // Ajax JSON取得
          axios
            .get(this.defaults.jsonUrl)
            .then((response) => {
              this.results.jsonRaw = response.data;
              setTimeout(() => {
                this.options.pageId = this.defaults.loadingPageId;
              }, this.defaults.loadingDelayMs);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        template: `
          <transition mode="out-in">
            <component :is="pageComponentName"></component>
          </transition>`,
      });

      // chat-welcome
      // ==========================================================================

      this.app.component("chat-welcome", {
        inject: ["app"],
        data() {
          return {
            defaults: {
              // JSONデータ
              json: this.app.json,
            },
            options: {},
            results: {},
          };
        },
        methods: {
          getJsonText(id) {
            return this.defaults.json[id].q_text;
          },
          onCloseButtonClick() {
            this.app.close();
          },
          onStartButtonClick() {
            this.app.show("main");
          },
        },
        template: `
          <article class="p-chat-window">
            <header class="p-chat-window__header">
              <h1 class="p-chat-window__header-title">{{getJsonText("main-title")}}</h1>
              <button class="c-chat-button p-chat-window__header-button" @click="onCloseButtonClick">×</button>
            </header>

            <div class="p-chat-window__body">
              <p class="p-chat-text">{{getJsonText("first-text")}}</p>
            </div>

            <footer class="p-chat-window__footer">
              <div class="p-chat-window__footer-buttons">
                <button class="c-chat-button c-chat-button-common p-chat-window__footer-button" @click="onStartButtonClick">{{getJsonText("first-button")}}</button>
              </div>
            </footer>
          </article>`,
      });

      // main
      // ==========================================================================

      this.app.component("chat-main", {
        inject: ["app"],
        data() {
          return {
            defaults: {
              // JSONデータ
              json: this.app.json,
              // JSON初期表示メッセージID
              jsonHomeId: "first-button",
              // 受信メッセージ表示遅延ms
              reciveMessageDelayMs: 1500,
              // DB API URL
              apiUrl: "./php/db_storage.php",
              // DB API 有効可否
              isApiEnabled: location.hostname !== "localhost",
            },
            options: {},
            results: {
              // メッセージ一覧マスタ
              messages: [],
              // スクロール位置調整px
              scrollTopOffsetPx: 0,
            },
          };
        },
        computed: {
          lastMessage() {
            return this.results.messages.length ? this.results.messages.at(-1) : false;
          },
          lastUserActionedMessageIndex() {
            return this.results.messages.length ? this.results.messages.findLastIndex((message) => message.withUserAction) : false;
          },
          isHistoryBackButtonEnabled() {
            return this.lastMessage ? this.lastMessage.parent : false;
          },
          isHomeButtonEnabled() {
            return this.lastMessage ? this.lastMessage.id !== this.defaults.jsonHomeId : false;
          },
        },
        methods: {
          onWindowResize() {
            // イベント間引き
            window.requestAnimationFrame(() => {
              // リスト上部paddingを取得
              const messagesPaddingTop = window.getComputedStyle(this.$refs.messages).getPropertyValue("padding-top");
              this.results.scrollTopOffsetPx = -parseFloat(messagesPaddingTop);
            });
          },
          getJsonText(id) {
            return this.defaults.json[id].q_text;
          },
          onCloseButtonClick() {
            this.app.show("welcome");

            // API送信
            this.postApi({
              q_id: "close",
              q_text: "閉じる",
            });
          },
          onMessageButtonClick(buttonId, buttonText) {
            // メッセージ表示
            this.pushSendMessage({ messageId: buttonId, withUserAction: true });
            this.pushReceiveMessage({ messageId: buttonId, withDelay: true });

            // API送信
            this.postApi({
              q_id: buttonId,
              q_text: buttonText,
            });
          },
          onMessageLinkClick(buttonId, buttonText) {
            // API送信
            this.postApi({
              q_id: buttonId,
              q_text: buttonText,
            });
          },
          onHistoryBackButtonClick() {
            // メッセージ表示
            this.pushReceiveMessage({
              messageId: this.lastMessage.parent,
              withUserAction: true,
            });

            // API送信
            this.postApi({
              q_id: "back",
              q_text: "ひとつ前にもどる",
            });
          },
          onHomeButtonClick() {
            // メッセージ表示
            this.pushReceiveMessage({
              messageId: this.defaults.jsonHomeId,
              withUserAction: true,
            });

            // API送信
            this.postApi({
              q_id: "back-to-top",
              q_text: "最初に戻る",
            });
          },
          pushSendMessage({ messageId, withUserAction } = {}) {
            // 対象アイテム取得
            const messageData = this.defaults.json[messageId];
            if (!messageData) {
              return false;
            }

            // メッセージ出力
            this.results.messages.push({
              type: "send",
              id: messageId,
              parent: messageData.parent,
              text: messageData.q_text,
              withUserAction,
            });

            // 自動スクロール
            this.scrollMessages();
          },
          pushReceiveMessage({ messageId, withUserAction, withDelay } = {}) {
            // 対象アイテム取得
            const messageData = this.defaults.json[messageId];
            if (!messageData) {
              return false;
            }

            if (withDelay) {
              // メッセージ出力
              const placeholderIndex =
                this.results.messages.push({
                  type: "placeholder",
                  id: messageId,
                  parent: messageData.parent,
                  withUserAction,
                }) - 1;

              // 遅延処理
              setTimeout(() => {
                // メッセージ出力
                this.results.messages.splice(placeholderIndex, 1, {
                  type: "receive",
                  id: messageId,
                  parent: messageData.parent,
                  text: messageData.a_text,
                  buttons: this.getMessageButtons(messageData.next_id),
                  withUserAction,
                });
              }, this.defaults.reciveMessageDelayMs);
            } else {
              // メッセージ出力
              this.results.messages.push({
                type: "receive",
                id: messageId,
                parent: messageData.parent,
                text: messageData.a_text,
                buttons: this.getMessageButtons(messageData.next_id),
                withUserAction,
              });
            }

            // 自動スクロール
            this.scrollMessages();
          },
          getMessageButtons(buttonIds) {
            if (!buttonIds) {
              return [];
            }

            // ボタン一覧の情報をまとめて格納
            return buttonIds.split(",").flatMap((buttonId) => {
              const buttonData = this.defaults.json[buttonId];
              if (!buttonData) {
                return [];
              }

              return {
                id: buttonId,
                text: buttonData.q_text,
                url: buttonData.link_url,
              };
            });
          },
          onMessageTransitionEnter(elem, done) {
            // マッチ時スクロール
            if (elem.matches("[data-type='receive']")) {
              this.scrollMessages();
            }
          },
          scrollMessages() {
            // DOM反映後
            nextTick(() => {
              // 自動スクロール
              const lastActionedMessageDom = this.$refs.messagesItem[this.lastUserActionedMessageIndex];
              if (lastActionedMessageDom) {
                this.$refs.messages.scrollTop = lastActionedMessageDom.offsetTop + this.results.scrollTopOffsetPx;
              }
            });
          },
          postApi(optionalDataObject) {
            if (!this.defaults.isApiEnabled) {
              return false;
            }

            // デフォルトオブジェクトとマージ
            const defaultDataObject = {
              id: _this.options.userData.chat_uuid,
              visits: _this.options.userData.chat_visits,
            };
            const dataObject = Object.assign(defaultDataObject, optionalDataObject);

            // Formオブジェクト生成
            const params = new URLSearchParams();
            for (const [key, value] of Object.entries(dataObject)) {
              params.append(key, value);
            }

            // Ajax POST
            axios
              .post(this.defaults.apiUrl, params)
              .then((response) => {
                // console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        mounted() {
          // リサイズイベント設定
          window.addEventListener("resize", this.onWindowResize);
          this.onWindowResize();

          // メッセージ表示
          this.pushReceiveMessage({
            messageId: this.defaults.jsonHomeId,
            withUserAction: true,
            withDelay: true,
          });
        },
        updated() {
          // コンソール出力
          // console.log(this);
        },
        beforeDestroy() {
          // リサイズイベント解除
          window.removeEventListener("resize", this.onWindowResize);
        },
        template: `
          <article class="p-chat-window -active">
            <header class="p-chat-window__header">
              <h1 class="p-chat-window__header-title">{{getJsonText("sub-title")}}</h1>
              <button class="c-chat-button p-chat-window__header-button" @click="onCloseButtonClick">×</button>
            </header>

            <div class="p-chat-window__body">
              <div class="p-chat-messages" ref="messages">
                <TransitionGroup tag="ol" mode="out-in" class="p-chat-messages__inner">
                  <li v-for="(message, index) in results.messages" class="p-chat-messages__item" ref="messagesItem" :key="index" :data-type="message.type">
                    <transition mode="out-in" @enter="onMessageTransitionEnter">
                      <div v-if="message.type === 'send'" class="p-chat-message" data-type="send">
                        <p v-if="message.text" class="p-chat-message__text">{{ message.text }}</p>
                      </div>

                      <div v-else-if="message.type === 'placeholder'" class="p-chat-message" data-type="placeholder">
                        <div class="p-chat-message__text">
                          <div id="circleG">
                            <div id="circleG_1" class="circleG"></div>
                            <div id="circleG_2" class="circleG"></div>
                            <div id="circleG_3" class="circleG"></div>
                          </div>
                        </div>
                      </div>

                      <div v-else-if="message.type === 'receive'" class="p-chat-message" data-type="receive">
                        <p v-if="message.text" class="p-chat-message__text">{{ message.text }}</p>
                        <ul v-if="message.buttons.length" class="p-chat-message__buttons">
                          <li v-for="button in message.buttons" :key="button.id">
                            <a v-if="button.url" class="c-chat-button c-chat-button-common p-chat-message__button" target="_blank" rel="noopener noreferrer" :href="button.url" @click="onMessageLinkClick(button.id, button.text)">{{ button.text }}</a>
                            <button v-else class="c-chat-button c-chat-button-common p-chat-message__button" @click="onMessageButtonClick(button.id, button.text)">{{ button.text }}</button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </li>
                </TransitionGroup>
              </div>
            </div>

            <footer class="p-chat-window__footer">
              <div class="p-chat-window__footer-buttons">
                <button class="c-chat-button c-chat-button-common p-chat-window__footer-button" :disabled="!isHistoryBackButtonEnabled" @click="onHistoryBackButtonClick">ひとつ前にもどる</button>
                <button class="c-chat-button c-chat-button-common p-chat-window__footer-button" :disabled="!isHomeButtonEnabled" @click="onHomeButtonClick">最初にもどる</button>
              </div>
            </footer>
          </article>`,
      });

      // app mount
      // ==========================================================================

      this.app.mount(this.elem);
    }

    useCookie(useCookie) {
      this.options.useCookie = useCookie;

      if (useCookie) {
        const savedCookie = this._instances.cookie.get();

        this.options.userData.chat_uuid = savedCookie.chat_uuid || createUuid();
        this.options.userData.chat_visits = savedCookie.chat_visits ? Number(savedCookie.chat_visits) + 1 : 1;

        this._instances.cookie.set(this.options.userData);
      } else {
        this.options.userData.chat_uuid = `undefined_${createUuid()}`;
        this.options.userData.chat_visits = null;

        this._instances.cookie.reset(["chat_uuid", "chat_visits"]);
      }
    }
  };
})();
